import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA4 from 'react-ga4';

export const RouteChangeTracker = () => {
  const { pathname, search } = useLocation();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!window.location.href.includes('localhost')) {
      ReactGA4.initialize(`${process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID}`);
    }

    setInitialized(true);
  }, []);

  useEffect(() => {
    if (initialized) {
      ReactGA4.send({ hitType: 'pageview', path: pathname + search, localtion: pathname, title: pathname });
    }
  }, [initialized, pathname, search]);
};
