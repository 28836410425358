import firebase from 'firebase/app';
import 'firebase/messaging';
import api from '../api';

const firebaseConfig = {
  apiKey: 'AIzaSyACBv6VoZ9UvNSqh0-VSN8VAjYgTaxfA74',
  authDomain: 'wevit-5cb4d.firebaseapp.com',
  databaseURL: 'https://wevit-5cb4d-default-rtdb.asia-southeast1.firebasedatabase.app',
  projectId: 'wevit-5cb4d',
  storageBucket: 'wevit-5cb4d.appspot.com',
  messagingSenderId: '37954563312',
  appId: '1:37954563312:web:8a38d0337bf199a8f22635',
  measurementId: 'G-ZZBYZW4XSG',
};

const mobile = /iphone|ipad|ipod|android/i.test(navigator.userAgent.toLowerCase());

if (!mobile) {
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);

  if (firebase?.messaging?.isSupported()) {
    const messaging = firebase.messaging();

    messaging
      .getToken({ vapidKey: 'BMjdeqE5eE9Dx17_V_AgdSwQwBCMuXuoEdr6EKetql4hcvzS4FYFhjNqjeZppbEHYVyn1cKdSsKAl5yciFZse7Q' })
      .then(async (token) => {
        const requestPermission = (token) => {
          Notification.requestPermission().then(async (permission) => {
            try {
              if (permission === 'granted') {
                // console.log('알림 권한이 허용됨');
                await api.post('/oauth/push_token', { device_id: token, type: 1 });
              }
            } catch (error) {
              throw new Error(error);
            }
          });
        };

        requestPermission(token);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        throw new Error(err);
      });
  }
  // Initialize Firebase Cloud Messaging and get a reference to the service
}
