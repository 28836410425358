/* eslint-disable react/destructuring-assignment */
import React, { useEffect } from 'react';
import './index.scss';
import { useErrorBoundary } from 'react-error-boundary';

const ErrorFallback = (error: any) => {
  // Network Error

  // API Error
  // console.error(error);
  const { resetBoundary } = useErrorBoundary();

  const handleBoundary = () => {
    if (!localStorage.getItem('errorCount')) {
      localStorage.setItem('errorCount', '1');
    } else {
      const count = Number(localStorage.getItem('errorCount'));

      if (count) {
        localStorage.removeItem('errorCount');
        window.location.reload();
      } else {
        // eslint-disable-next-line no-plusplus
        localStorage.setItem('errorCount', `${count + 1}`);
        resetBoundary();
      }
    }
  };

  useEffect(() => {
    return () => {
      localStorage.removeItem('errorCount');
    };
  }, []);

  return (
    <div role='alert' className='error_boundary__wrapper'>
      <h1>Something went wrong.</h1>

      <pre style={{ color: 'red' }}>{error?.message || ''}</pre>

      <button
        type='button'
        onClick={() => {
          handleBoundary();
        }}
        aria-hidden
      >
        Try again
      </button>
    </div>
  );
};

export default ErrorFallback;
