/* eslint-disable consistent-return */
import axios from 'axios';

const AccessToken = localStorage.getItem('token') || '';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.withCredentials = true;

if (AccessToken) {
  axios.defaults.headers.common.Authorization = `Bearer ${AccessToken}`;
}

const api = axios.create({
  headers: {
    'Content-Type': 'application/json;charset=utf-8',
  },
});

api.interceptors.request.use(
  (res) => res,
  (error) => {
    return Promise.reject(error);
  },
);

api.interceptors.response.use(
  (res) => res,
  async ({ response }) => {
    try {
      const { status } = response;

      // 토큰 만료 시, RefreshToken을 통해 자동 토큰 갱신 프로세스
      if (status === 401) {
        // 재발급 한 토큰으로 재 로그인 시행
        alert('Token period has expired. Please try logging in again.');
        window.location.href = '/login';
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
);

export default api;
