export const toastOptions = {
  hideProgressBar: true,
  closeBuutton: false,
  autoClose: 2000,
  isLoading: false,
  style: {
    width: '80%',
    margin: '0 auto',
    borderRadius: '8px',
    color: '#fff',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
  },
};

export const toastStyles = {
  style: {
    width: '80%',
    margin: '0 auto',
    borderRadius: '8px',
    color: '#fff',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
  },
};
