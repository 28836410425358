/* eslint-disable no-shadow-restricted-names */
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.scss';
import './assets/fonts/font.scss';
import './color.scss';
import 'react-toastify/dist/ReactToastify.css';
import { IntlProvider } from 'react-intl';
import { RecoilRoot } from 'recoil';
import { QueryClient, QueryClientProvider } from 'react-query';
// import { ReactQueryDevtools } from 'react-query/devtools';
import { HelmetProvider } from 'react-helmet-async';
import { ToastContainer, Zoom } from 'react-toastify';
import TagManager from 'react-gtm-module';
import en from './locale/en.json';
import ko from './locale/ko.json';
import App from './components/App';
import './config/firebaseConfige.js';

// Google Tag Manager 관리
const tagManagerArgs1 = {
  gtmId: `${process.env.REACT_APP_GOOGLE_ANALYTICS_TAG_ID_1}`,
};

TagManager.initialize(tagManagerArgs1);

const tagManagerArgs2 = {
  gtmId: `${process.env.REACT_APP_GOOGLE_ANALYTICS_TAG_ID_2}`,
};

TagManager.initialize(tagManagerArgs2);

const tagManagerArgs3 = {
  gtmId: `${process.env.REACT_APP_GOOGLE_ANALYTICS_TAG_ID_3}`,
};

TagManager.initialize(tagManagerArgs3);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: false,
      suspense: true,
    },
  },
});

// 다국어
const locale = navigator.language;
const messages = { 'en-US': en, 'ko-KR': ko }[locale];

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <RecoilRoot>
      <IntlProvider locale={locale} messages={messages}>
        <HelmetProvider>
          <BrowserRouter>
            <App />
            {/* react portal, custom modal  */}
            <div id='root_modal' />

            <ToastContainer draggable={false} style={{ top: '5%', left: '50%', transform: 'translate(-50%, 0)' }} transition={Zoom} />
          </BrowserRouter>
        </HelmetProvider>
      </IntlProvider>
    </RecoilRoot>
    {/* devtools */}
    {/* <ReactQueryDevtools initialIsOpen /> */}
  </QueryClientProvider>,
  document.getElementById('root'),
);
