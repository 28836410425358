import React from 'react';
import './index.scss';

const Loading = ({ hasMore = false }) => {
  return (
    <div className={`Loading_Wrapper ${hasMore ? 'hasMore' : ''}`}>
      <div className='Loading'>Loading...</div>
    </div>
  );
};

export default Loading;
