import React, { useEffect, useCallback, Suspense } from 'react';
import { Routes, Route, useNavigate, Navigate, useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { toast } from 'react-toastify';
import { ErrorBoundary } from 'react-error-boundary';

import { useSwipeable } from 'react-swipeable';
import { Helmet } from 'react-helmet-async';
import api from '../api';
import { userState } from '../recoil/user/atom';
import { toastOptions } from '../utils/toastOptions';
import { RouteChangeTracker } from '../config/RouteChangeTracker ';
import { retryLazy } from '../utils/lazyUtil';
import Loading from './Loading';
import ErrorFallback from './ErrorBoundary';

const CreateCategory = retryLazy(() => import('../pages/Community/CreateCategory'));
const Login = retryLazy(() => import('../pages/Login'));
const Products = retryLazy(() => import('../pages/Products'));
const Posting = retryLazy(() => import('../pages/Posting'));
const Filter = retryLazy(() => import('../pages/Filter'));
const NeighborhoodSettings = retryLazy(() => import('../pages/NeighborhoodSettings'));
const ProductsDetail = retryLazy(() => import('../pages/ProductsDetail'));
const ChatList = retryLazy(() => import('../pages/ChatList'));
const Chat = retryLazy(() => import('../pages/Chat'));
const Account = retryLazy(() => import('../pages/Account'));
const EditAccount = retryLazy(() => import('../pages/Account/EditAccount'));
const MyKeywords = retryLazy(() => import('../pages/Account/MyKeywords'));
const MySale = retryLazy(() => import('../pages/Account/MySale'));
const MyPosts = retryLazy(() => import('../pages/Account/MyPosts'));
const MyPurchase = retryLazy(() => import('../pages/Account/MyPurchase'));
const MyFavorites = retryLazy(() => import('../pages/Account/MyFavorites'));
const Review = retryLazy(() => import('../pages/Account/Review'));
const MyGreen = retryLazy(() => import('../pages/Account/MyGreen'));
const Blocked = retryLazy(() => import('../pages/Account/Blocked'));
const Announcements = retryLazy(() => import('../pages/Account/Announcements'));
const Support = retryLazy(() => import('../pages/Account/Support'));
const Settings = retryLazy(() => import('../pages/Account/Settings'));
const Search = retryLazy(() => import('../pages/Search'));
const Alerts = retryLazy(() => import('../pages/Alerts'));
const Policy = retryLazy(() => import('../pages/Policy'));
const Terms = retryLazy(() => import('../pages/Terms'));
const Randing = retryLazy(() => import('../pages/Randing'));
const Intro = retryLazy(() => import('../pages/Intro'));
const Community = retryLazy(() => import('../pages/Community'));
const CreateCommunityBoard = retryLazy(() => import('../pages/Community/CreateCommunityBoard'));
const Bookmarks = retryLazy(() => import('../pages/Community/Bookmarks'));
const Board = retryLazy(() => import('../pages/Community/Board'));
const AccountBaords = retryLazy(() => import('../pages/Account/Boards'));
const Boards = retryLazy(() => import('../pages/Community/Boards'));
// const Category = retryLazy(() => import('../pages/Community/Category'));
const CommunitySearch = retryLazy(() => import('../pages/Community/Search'));
const MoreView = retryLazy(() => import('../pages/Community/Viewer'));
const UserPosts = retryLazy(() => import('../pages/UserPosts'));
const NotFound = retryLazy(() => import('../pages/NotFound'));

const App = () => {
  const token: string = localStorage.getItem('token') || '';
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [user, setUser] = useRecoilState(userState);

  const fetchUserInfo = useCallback(
    async (token: string) => {
      try {
        // 401 토큰 전송값 없음
        // 411 탈퇴한 계정
        // 412 블랙리스트 계정 [주로 결제관련 관리자에 의한 추방]
        // 413 리포트당한 계정
        // 441 회원데이터 조회 안됨
        // 442 JWT 토큰 인증 유효시간 지남 [현재 체크안함]
        // 505 토큰 값 잘못됨 [위 변조]

        // 로그인시 리다이렉션 코드
        // 411 탈퇴회원
        // 412 블랙리스트 계정 [주로 결제관련 관리자에 의한 추방]
        // 413 리포트당한 계정

        await api.post('/mypage/my_account_info', { token }).then((res) => {
          const { code, data, message } = res.data;

          if (code === 200) {
            setUser(data[0]);
          } else {
            localStorage.removeItem('token');

            toast.error(message, {
              ...toastOptions,
              onClose: () => navigate('/login'),
            });
          }
        });
      } catch (error: any) {
        throw new Error(error);
      }
    },
    [navigate, setUser],
  );

  useEffect(() => {
    const scrollInfo = sessionStorage.getItem('scrollInfo') || undefined;

    if (scrollInfo) {
      const info = JSON.parse(scrollInfo);

      if (!(window.history.state.idx === info.history || info.history + 1 === window.history.state.idx)) {
        sessionStorage.removeItem('scrollInfo');
      }
    }
  }, [pathname]);

  useEffect(() => {
    if (token) {
      // 유저 정보 갱신
      if (!user || pathname === '/account') {
        fetchUserInfo(token);
      }
    } else {
      // 비로그인 페이지 관리
      const isHome = !!(pathname === '/');
      const isLogin = pathname.includes('/login');
      const isIntro = pathname.includes('/intro');
      const isProductsPage = pathname.includes('/products');
      const isProductDetailPage = pathname.includes('/product');
      const isSearchPage = pathname.includes('/search');
      const isFilterPage = pathname.includes('/filter');
      const isRandingPage = pathname.includes('/service');
      const isAccountPage = pathname.includes('/account'); // 로그아웃 시 Main Page 전환하기 위해 체크 함
      const isPolicyPage = pathname.includes('/policy'); // 로그아웃 시 Main Page 전환하기 위해 체크 함
      const isTermsPage = pathname.includes('/terms'); // 로그아웃 시 Main Page 전환하기 위해 체크 함
      const isPostsPage = pathname.includes('/posts') && !pathname.includes('/account');

      const isCommunityPage = pathname.includes('/community') && !(pathname.includes('/createBoard') && pathname.includes('/createCategory'));

      if (
        // 조건부 제외한 페이지는 비로그인 접근 불가
        !(
          isHome ||
          isLogin ||
          isIntro ||
          isProductsPage ||
          isProductDetailPage ||
          isRandingPage ||
          isSearchPage ||
          isFilterPage ||
          isAccountPage ||
          isPostsPage ||
          isCommunityPage ||
          isPolicyPage ||
          isTermsPage
        )
      ) {
        navigate('/login');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, fetchUserInfo, navigate, pathname]);

  RouteChangeTracker();

  const mobile = /iphone|ipad|ipod|android/i.test(navigator.userAgent.toLowerCase());

  // 모바일 제스처 함수
  const onMobileGesture = useSwipeable({
    onSwipedRight: (eventData: any) => {
      // eslint-disable-next-line no-useless-return
      if (!mobile) return;

      const { target } = eventData.event;
      if (target && target?.nodeName === 'IMG') return;

      if (eventData.dir === 'Right') {
        if (pathname === '/products' || pathname.includes('/posting')) return;

        if (window.history.state.idx === 0) {
          navigate('/products');
        } else {
          navigate(-1);
        }
      }
    },
    delta: { right: 150 }, // min distance(px) before a swipe starts. *See Notes*
    preventScrollOnSwipe: false, // prevents scroll during swipe (*See Details*)
    trackTouch: true, // track touch input
    trackMouse: false, // track mouse input
    rotationAngle: 0, // set a rotation angle
    swipeDuration: Infinity, // allowable duration of a swipe (ms). *See Notes*
    touchEventOptions: { passive: true }, // options for touch listeners (*See Details*)
  });

  return (
    <div className='App' {...onMobileGesture}>
      <Helmet>
        <title>Wevitt</title>
      </Helmet>

      <Suspense fallback={<Loading />}>
        <ErrorBoundary fallback={<ErrorFallback />}>
          <Routes>
            {/* v6 => Redirect 대체 */}
            <Route path='/' element={<Navigate replace to='/products' />} />
            <Route path='/login' element={<Login />} />
            <Route path='/intro' element={<Intro />} />
            <Route path='/search' element={<Search />} />
            <Route path='/alerts' element={<Alerts />} />
            <Route path='/neighborhood' element={<NeighborhoodSettings />} />
            <Route path='/products' element={<Products />} />
            <Route path='/posting' element={<Posting />}>
              <Route path='edit/:id' element={<Posting />} /> {/* id => 상품 Id (Edit Mode) */}
            </Route>
            <Route path='/draft/:id' element={<Posting />} /> {/* id => 상품 Id (Draft Mode) */}
            <Route path='/posts/:hashId' element={<UserPosts />} />
            <Route path='/filter' element={<Filter />} />
            <Route path='/product/:id' element={<ProductsDetail />} /> {/* id => 상품 Id */}
            <Route path='/chatlist' element={<ChatList />} />
            <Route path='/chat/:offerHashId' element={<Chat />} /> {/* id => offer_hash_id */}
            <Route path='/account' element={<Account />} />
            <Route path='/account/edit' element={<EditAccount />} />
            <Route path='/account/keywords' element={<MyKeywords />} />
            <Route path='/account/sale' element={<MySale />} />
            <Route path='/account/posts' element={<MyPosts />} />
            <Route path='/account/purchase' element={<MyPurchase />} />
            <Route path='/account/favorites' element={<MyFavorites />} />
            <Route path='/account/blocked' element={<Blocked />} />
            {/* my commnity */}
            <Route path='/account/boards' element={<AccountBaords />} />
            <Route path='/account/communityComments' element={<Community />} />
            <Route path='/review' element={<Review />}>
              <Route path='/review/:id' element={<Review />} />
            </Route>
            <Route path='/account/green' element={<MyGreen />} />
            <Route path='/green/:id' element={<MyGreen />} /> {/* id => 다른 유저 Id */}
            <Route path='/account/announcements' element={<Announcements />} />
            <Route path='/account/support' element={<Support />} />
            <Route path='/account/settings' element={<Settings />} />
            {/* 이용약관 */}
            <Route path='/policy' element={<Policy />} />
            <Route path='/terms' element={<Terms />} />
            {/* Randing page */}
            <Route path='/service' element={<Randing />} />
            {/* Commnunity */}
            <Route path='/community' element={<Community />}>
              <Route path='/community/:categoryId' element={<Community />} />
            </Route>
            <Route path='/community/:categoryId/createCategory' element={<CreateCategory />} />
            <Route path='/community/:categoryId/createBoard' element={<CreateCommunityBoard />} />
            <Route path='/community/:categoryId/:boardId' element={<Board />} />
            {/* Condo 한정 */}
            <Route path='/community/:categoryId/:bccId/boards' element={<Boards />} />
            <Route path='/community/:categoryId/:boardId/edit' element={<CreateCommunityBoard />} />
            <Route path='/community/bookmarks' element={<Bookmarks />} />
            <Route path='/community/search' element={<CommunitySearch />} />
            <Route path='/community/search/category' element={<MoreView />} />
            <Route path='/community/search/boards' element={<MoreView />} />
            {/* Not Found Page */}
            <Route path='/*' element={<NotFound />} />
          </Routes>
        </ErrorBoundary>
      </Suspense>
    </div>
  );
};

export default App;
